import { Component, inject, signal } from '@angular/core';

import { SnackbarService } from '../shared/services';

@Component({
	selector: 'app-test',
	standalone: true,
	imports: [],
	templateUrl: './test.component.html',
	styleUrl: './test.component.scss'
})
export class TestComponent {
	private readonly snackbarService = inject(SnackbarService);

	protected readonly text = signal('');
	protected readonly type = signal<'success' | 'error'>('error');

	onClick() {
		console.log('text', this.text());
		this.type() === 'success'
			? this.snackbarService.success(this.text(), 100000)
			: this.snackbarService.error(this.text(), 100000);
	}
}
