import { DestroyRef, inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { Observable, of, filter } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

import { LocalStorageService } from '../services';
import { UserService } from '../../core/services';
import { decodeJwt } from '../utils/jwt.utils';
import { JwtResource } from '../models';

export const canActivateMain: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const router = inject(Router);
	const destroyRef = inject(DestroyRef);
	const localStorageService = inject(LocalStorageService);
	const userService = inject(UserService);

	const token = localStorageService.getItem<string>('goldup-token');
	const jwtFull = !!token
		? (decodeJwt(token) as JwtResource).type === 'full'
		: null;
	let canActivate: Observable<boolean>;

	if (!jwtFull) {
		const queryParams = { returnUrl: state.url };
		router.navigate(['/', 'auth'], { queryParams });
		canActivate = of(!!token);
	} else {
		canActivate = toObservable(userService.isAuthenticated).pipe(
			takeUntilDestroyed(destroyRef),
			filter(isAuth => isAuth)
		);
	}

	return canActivate;
};
