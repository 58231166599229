import { inject } from '@angular/core';
import {
	HttpRequest,
	HttpErrorResponse,
	HttpHandlerFn,
	HttpInterceptorFn
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { SpinnerService } from '../services/spinner.service';

let totalRequests = 0;

export const spinnerInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
) => {
	const spinnerService = inject(SpinnerService);

	totalRequests++;
	spinnerService.show();

	return next(req).pipe(
		finalize(() => {
			totalRequests--;

			if (totalRequests === 0) {
				spinnerService.hide();
			}
		}),
		catchError((error: HttpErrorResponse) => {
			spinnerService.hide();
			return throwError(() => error);
		})
	);
};
