import { Routes } from '@angular/router';

import { canActivateMain } from './shared/guards';
import { TestComponent } from './test/test.component';

export const routes: Routes = [
	{ path: '', redirectTo: 'main', pathMatch: 'full' },
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.routes').then(m => m.routes)
	},
	{
		path: 'main',
		canActivate: [canActivateMain],
		loadChildren: () => import('./main/main.routes').then(m => m.routes)
	},
	{
		path: 'test',
		component: TestComponent
	},
	{
		path: '**',
		loadComponent: () =>
			import('./theme/not-found/not-found.component').then(
				mod => mod.NotFoundComponent
			)
	}
];
