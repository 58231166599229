import { Component, OnInit, inject } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { filter, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import './shared/extensions';
import { environment } from '../environments/environment';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { FooterComponent } from './theme/footer/footer.component';
import { HeaderComponent } from './theme/header/header.component';
import { SidebarComponent } from './theme/sidebar/sidebar.component';
import { UsersManagerService } from './core/services';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterOutlet,
		HeaderComponent,
		SidebarComponent,
		FooterComponent,
		SnackbarComponent,
		SpinnerComponent
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
	private readonly router = inject(Router);
	private readonly usersManagerService = inject(UsersManagerService);
	private readonly translate = inject(TranslateService);

	private currentRoute: string = '/';

	title = 'goldup-webapp';
	sidebarOpened = false;
	isLogin = false;

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				tap((event: any) => (this.isLogin = event.url.startsWith('/auth'))),
				tap(() => document.body.classList.toggle('t02', !this.isLogin))
			)
			.subscribe((event: any) => (this.currentRoute = event.url));

		this.usersManagerService.autoLogin();

		this.translate.use(environment.locale);
	}
}
