import { inject } from '@angular/core';
import {
	HttpRequest,
	HttpErrorResponse,
	HttpHandlerFn,
	HttpInterceptorFn
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorManagerService } from '../services';

export const errorInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
) => {
	const errorManagerService = inject(ErrorManagerService);

	// if (req.method.toUpperCase().in('POST', 'PUT', 'DELETE')) {
	errorManagerService.clearExceptions();
	// }

	return next(req).pipe(
		catchError((error: HttpErrorResponse) => {
			if (error.error?.exception) {
				errorManagerService.addException(error.error.exception);
			}

			return throwError(() => error);
		})
	);
};
