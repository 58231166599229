import { inject, untracked } from '@angular/core';
import {
	HttpRequest,
	HttpHandlerFn,
	HttpInterceptorFn
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { UserService } from '../../core/services';

export const cultureInfoInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
) => {
	const userService = inject(UserService);

	const settings = untracked(userService.settings);
	const language = settings?.generals?.find(g => g.code === 'Lang');
	const cultureInfo = language?.value ?? environment.locale;
	const hasCultureInfo = req.params.has('cultureInfo');

	if (!hasCultureInfo) {
		req = req.clone({
			params: req.params.append('cultureInfo', cultureInfo)
		});
	}

	return next(req);
};
