import {
	ApplicationConfig,
	LOCALE_ID,
	importProvidersFrom,
	provideZoneChangeDetection
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
	HttpBackend,
	HttpClient,
	provideHttpClient,
	withInterceptors
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { DatePipe, DecimalPipe, KeyValuePipe } from '@angular/common';

import { routes } from './app.routes';
import { environment } from '../environments/environment';
import {
	authInterceptor,
	errorInterceptor,
	refreshTokenInterceptor,
	spinnerInterceptor,
	cultureInfoInterceptor
} from './shared/interceptors';
import { RxStompService, rxStompServiceFactory } from './core/rx-stomp';

function getBaseUrl() {
	let baseUrl = document.getElementsByTagName('base')[0].href;

	if (baseUrl && (baseUrl.endsWith('\\') || baseUrl.endsWith('/')))
		baseUrl = baseUrl.substring(0, baseUrl.length - 1);

	return baseUrl;
}

export function HttpLoaderFactory(
	httpHandler: HttpBackend
): TranslateHttpLoader {
	return new TranslateHttpLoader(new HttpClient(httpHandler));
}

export const provideTranslation = () => ({
	defaultLanguage: environment.locale,
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend]
	}
});

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideAnimations(),
		{ provide: 'BASE_URL', useFactory: getBaseUrl },
		{
			provide: LOCALE_ID,
			useValue: environment.locale
		},
		{
			provide: RxStompService,
			useFactory: rxStompServiceFactory
		},
		importProvidersFrom([TranslateModule.forRoot(provideTranslation())]),
		provideHttpClient(
			withInterceptors([
				authInterceptor,
				spinnerInterceptor,
				errorInterceptor,
				refreshTokenInterceptor,
				cultureInfoInterceptor
			])
		),
		DatePipe,
		KeyValuePipe,
		DecimalPipe
	]
};
