<div class="container-fluid mt-5">
	<div class="row">
		<div class="offset-3 col-6">
			<div class="row">
				<div class="col">
					<label>Inserisci testo</label>
					<input
						type="text"
						class="form-control"
						(input)="text.set($any($event.target).value)"
					/>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col">
					<label>Tipo</label>
					<div>
						@for (option of ['success', 'error']; track type) {
							<div class="form-check form-check-inline">
								<input
									type="radio"
									class="form-check-input"
									[value]="option"
									[checked]="option === type()"
									(change)="type.set($any(option))"
								/>
								<label class="form-check-label">{{ option }}</label>
							</div>
						}
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col">
					<button
						type="button"
						class="btn btn-primary"
						(click)="onClick()"
					>
						Mostra snackbar
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
