import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { filter, tap } from 'rxjs';

import { LocalStorageService } from '../services/local-storage.service';

const checkRefreshToken = (
	localStorageService: LocalStorageService,
	httpEvent: HttpEvent<any>
) => {
	const response = httpEvent as HttpResponse<any>;
	const refreshToken = response?.headers?.get('refreshtoken') || null;

	if (refreshToken) localStorageService.setItem('goldup-token', refreshToken);
};

export const refreshTokenInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
) => {
	const localStorageService = inject(LocalStorageService);

	return next(req).pipe(
		filter(httpEvent => httpEvent instanceof HttpResponse),
		tap(httpEvent => checkRefreshToken(localStorageService, httpEvent))
	);
};
