import { inject } from '@angular/core';
import {
	HttpRequest,
	HttpErrorResponse,
	HttpHandlerFn,
	HttpInterceptorFn
} from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { LocalStorageService } from '../services';

export const authInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
) => {
	const router = inject(Router);
	const localStorageService = inject(LocalStorageService);
	const tokenStandard = localStorageService.getItem('goldup-token');
	const tokenSecure = localStorageService.getItem('goldup-token-secure');
	const token = tokenSecure ?? tokenStandard;

	if (token) {
		req = req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
	}

	return next(req).pipe(
		tap(
			() =>
				!!tokenSecure && localStorageService.removeItem('goldup-token-secure')
		),
		catchError((error: HttpErrorResponse) => {
			if (error.status === 401) {
				if (!req.headers.get('x-skip-redirect')) {
					localStorageService.removeItem('goldup-token');
					router.navigate(['/auth']);
				}
			}

			return throwError(() => error);
		})
	);
};
